export const sports_teams = {
  NFL: [
    "Arizona Cardinals",
    "Atlanta Falcons",
    "Baltimore Ravens",
    "Buffalo Bills",
    "Carolina Panthers",
    "Chicago Bears",
    "Cincinnati Bengals",
    "Cleveland Browns",
    "Dallas Cowboys",
    "Denver Broncos",
    "Detroit Lions",
    "Green Bay Packers",
    "Houston Texans",
    "Indianapolis Colts",
    "Jacksonville Jaguars",
    "Kansas City Chiefs",
    "Las Vegas Raiders",
    "Los Angeles Chargers",
    "Los Angeles Rams",
    "Miami Dolphins",
    "Minnesota Vikings",
    "New England Patriots",
    "New Orleans Saints",
    "New York Giants",
    "New York Jets",
    "Philadelphia Eagles",
    "Pittsburgh Steelers",
    "San Francisco 49ers",
    "Seattle Seahawks",
    "Tampa Bay Buccaneers",
    "Tennessee Titans",
    "Washington Commanders",
  ],
  basketballteams: [
    "Atlanta Hawks",
    "Boston Celtics",
    "Brooklyn Nets",
    "Charlotte Hornets",
    "Chicago Bulls",
    "Cleveland Cavaliers",
    "Dallas Mavericks",
    "Denver Nuggets",
    "Detroit Pistons",
    "Golden State Warriors",
    "Houston Rockets",
    "Indiana Pacers",
    "LA Clippers",
    "Los Angeles Lakers",
    "Memphis Grizzlies",
    "Miami Heat",
    "Milwaukee Bucks",
    "Minnesota Timberwolves",
    "New Orleans Pelicans",
    "New York Knicks",
    "Oklahoma City Thunder",
    "Orlando Magic",
    "Philadelphia 76ers",
    "Phoenix Suns",
    "Portland Trail Blazers",
    "Sacramento Kings",
    "San Antonio Spurs",
    "Toronto Raptors",
    "Utah Jazz",
    "Washington Wizards",
  ],
  NHL : [
    "Anaheim Ducks",
    "Arizona Coyotes",
    "Boston Bruins",
    "Buffalo Sabres",
    "Calgary Flames",
    "Carolina Hurricanes",
    "Chicago Blackhawks",
    "Colorado Avalanche",
    "Columbus Blue Jackets",
    "Dallas Stars",
    "Detroit Red Wings",
    "Edmonton Oilers",
    "Florida Panthers",
    "Los Angeles Kings",
    "Minnesota Wild",
    "Montreal Canadiens",
    "Nashville Predators",
    "New Jersey Devils",
    "New York Islanders",
    "New York Rangers",
    "Ottawa Senators",
    "Philadelphia Flyers",
    "Pittsburgh Penguins",
    "San Jose Sharks",
    "Seattle Kraken",
    "St. Louis Blues",
    "Tampa Bay Lightning",
    "Toronto Maple Leafs",
    "Vancouver Canucks",
    "Vegas Golden Knights",
    "Washington Capitals",
    "Winnipeg Jets"
],
MLB:[
    "Arizona Diamondbacks",
    "Atlanta Braves",
    "Baltimore Orioles",
    "Boston Red Sox",
    "Chicago White Sox",
    "Chicago Cubs",
    "Cincinnati Reds",
    "Cleveland Guardians",
    "Colorado Rockies",
    "Detroit Tigers",
    "Houston Astros",
    "Kansas City Royals",
    "Los Angeles Angels",
    "Los Angeles Dodgers",
    "Miami Marlins",
    "Milwaukee Brewers",
    "Minnesota Twins",
    "New York Yankees",
    "New York Mets",
    "Oakland Athletics",
    "Philadelphia Phillies",
    "Pittsburgh Pirates",
    "San Diego Padres",
    "San Francisco Giants",
    "Seattle Mariners",
    "St. Louis Cardinals",
    "Tampa Bay Rays",
    "Texas Rangers",
    "Toronto Blue Jays",
    "Washington Nationals"
],
NCAA:[
    "Air Force Falcons Football",
    "Akron Zips Football",
    "Alabama Crimson Tide Football",
    "Appalachian State Mountaineers Football",
    "Arizona Wildcats Football",
    "Arizona State Sun Devils Football",
    "Arkansas Razorbacks Football",
    "Arkansas State Red Wolves Football",
    "Army Black Knights Football",
    "Auburn Tigers Football",
    "Ball State Cardinals Football",
    "Baylor Bears Football",
    "Boise State Broncos Football",
    "Boston College Eagles Football",
    "Bowling Green Falcons Football",
    "Buffalo Bulls Football",
    "BYU Cougars Football",
    "California Golden Bears Football",
    "Central Michigan Chippewas Football",
    "Charlotte 49ers Football",
    "Cincinnati Bearcats Football",
    "Clemson Tigers Football",
    "Coastal Carolina Chanticleers Football",
    "Colorado Buffaloes Football",
    "Colorado State Rams Football",
    "Connecticut Huskies Football",
    "Duke Blue Devils Football",
    "East Carolina Pirates Football",
    "Eastern Michigan Eagles Football",
    "FIU Panthers Football (Florida International)",
    "Florida Gators Football",
    "Florida Atlantic Owls Football",
    "Florida State Seminoles Football",
    "Fresno State Bulldogs Football",
    "Georgia Bulldogs Football",
    "Georgia Southern Eagles Football",
    "Georgia State Panthers Football",
    "Georgia Tech Yellow Jackets Football",
    "Hawaii Rainbow Warriors Football",
    "Houston Cougars Football",
    "Idaho Vandals Football",
    "Illinois Fighting Illini Football",
    "Indiana Hoosiers Football",
    "Iowa Hawkeyes Football",
    "Iowa State Cyclones Football",
    "Kansas Jayhawks Football",
    "Kansas State Wildcats Football",
    "Kent State Golden Flashes Football",
    "Kentucky Wildcats Football",
    "Louisiana Ragin' Cajuns Football",
    "Louisiana Tech Bulldogs Football",
    "Louisville Cardinals Football",
    "LSU Tigers Football",
    "Marshall Thundering Herd Football",
    "Maryland Terrapins Football",
    "Massachusetts Minutemen Football (UMass)",
    "Memphis Tigers Football",
    "Miami Hurricanes Football",
    "Miami (Ohio) RedHawks Football",
    "Michigan Wolverines Football",
    "Michigan State Spartans Football",
    "Middle Tennessee Blue Raiders Football",
    "Minnesota Golden Gophers Football",
    "Mississippi State Bulldogs Football",
    "Missouri Tigers Football",
    "Navy Midshipmen Football",
    "Nebraska Cornhuskers Football",
    "Nevada Wolf Pack Football",
    "New Mexico Lobos Football",
    "New Mexico State Aggies Football",
    "North Carolina Tar Heels Football",
    "North Carolina State Wolfpack Football",
    "North Texas Mean Green Football",
    "Northern Illinois Huskies Football",
    "Northwestern Wildcats Football",
    "Notre Dame Fighting Irish Football",
    "Ohio Bobcats Football",
    "Ohio State Buckeyes Football",
    "Oklahoma Sooners Football",
    "Oklahoma State Cowboys Football",
    "Old Dominion Monarchs Football",
    "Ole Miss Rebels Football",
    "Oregon Ducks Football",
    "Oregon State Beavers Football",
    "Penn State Nittany Lions Football",
    "Pittsburgh Panthers Football",
    "Purdue Boilermakers Football",
    "Rice Owls Football",
    "Rutgers Scarlet Knights Football",
    "San Diego State Aztecs Football",
    "San José State Spartans Football",
    "SMU Mustangs Football (Southern Methodist)",
    "South Alabama Jaguars Football",
    "South Carolina Gamecocks Football",
    "South Florida Bulls Football",
    "Southern Miss Golden Eagles Football",
    "Stanford Cardinal Football",
    "Syracuse Orange Football",
    "TCU Horned Frogs Football (Texas Christian)",
    "Temple Owls Football",
    "Tennessee Volunteers Football",
    "Texas Longhorns Football",
    "Texas A&M Aggies Football",
    "Texas State Bobcats Football",
    "Texas Tech Red Raiders Football",
    "Toledo Rockets Football",
    "Troy Trojans Football",
    "Tulane Green Wave Football",
    "Tulsa Golden Hurricane Football",
    "UCF Knights Football (Central Florida)",
    "UCLA Bruins Football",
    "ULM Warhawks Football (Louisiana-Monroe)",
    "UNLV Rebels Football (Nevada-Las Vegas)",
    "USC Trojans Football (Southern California)",
    "Utah Utes Football",
    "Utah State Aggies Football",
    "UTEP Miners Football (Texas-El Paso)",
    "UTSA Roadrunners Football (Texas-San Antonio)",
    "Vanderbilt Commodores Football",
    "Virginia Cavaliers Football",
    "Virginia Tech Hokies Football",
    "Wake Forest Demon Deacons Football",
    "Washington Huskies Football",
    "Washington State Cougars Football",
    "West Virginia Mountaineers Football",
    "Western Kentucky Hilltoppers Football",
    "Western Michigan Broncos Football",
    "Wisconsin Badgers Football",
    "Wyoming Cowboys Football"
],
NFL_text:[
    "From the NFL preseason to the electrifying Super Bowl, Sourced Tickets proudly offers NFL tickets for events spanning the entire season. The NFL calendar typically commences in August, ushering in the regular season from September through January, followed by the commencement of the NFL playoffs and Pro Bowl later in the month. Through the Sourced Tickets platform, you can secure NFL tickets to witness any of the 32 teams in action, including occasional involvement in various special events like the NFL International Series, and high-profile clashes held on Black Friday, Sunday evenings, Monday nights, and Thursday nights. Secure your tickets with Sourced Tickets today!"
],
NBA_text:[
    "Select your preferred team above to access its NBA schedule for the season, or explore other links on the page for noteworthy special events such as the NBA All-Star Game, a thrilling showcase of skill and athleticism featuring attractions like the annual 3-point contest and slam dunk competition. As you browse for NBA basketball tickets on the Sourced Tickets platform, take advantage of our array of user-friendly tools, including interactive seating charts for pinpointing the ideal location in the arena, on-page filters to refine your search for the perfect matchup, and a flexible price range toolbar for finding affordable NBA tickets that fit your budget.NBA ticket prices fluctuate throughout the season, so check back regularly for the latest team-by-team game breakdown. If your team progresses to the postseason, don't forget to revisit us for NBA playoff tickets to cheer them on as they vie for the prestigious Larry O’Brien NBA Championship Trophy."
],
NHL_text:[
    "From the initial puck drop of the preseason to the climax of the Stanley Cup Final, NHL tickets are readily available on Sourcedtickets.com. Experience all the thrilling one-on-one breakaways and remarkable glove saves from seats offering the perfect views and amenities you desire. Don't miss out on witnessing visits from the league's top stars throughout the season.Passionate NHL fans across North America flock to their local arenas to support their beloved teams, and with Sourced Tickets, you'll have the opportunity to attend the most exciting games in every city."
],
MLB_text:[
    "Sourced Tickets is your go-to destination for MLB tickets to catch all the action, whether it's home or away games. Explore seats for spring training, regular season matchups, and postseason clashes at prices that cater to every fan's budget. Whether you pledge allegiance to the American League or the National League, we have the MLB baseball tickets you desire at prices that won't break the bank. And when your team clinches a postseason berth, rest assured that our inventory of MLB Playoff tickets and World Series tickets will be readily available.Additionally, we offer access to special baseball events, including the MLB All-Star Game and weekend festivities. Purchase your MLB tickets conveniently online through Sourced Tickets. Buying MLB tickets from our platform is a breeze. Simply select your team from the list above and choose the game you wish to attend. You'll be presented with the entire game schedule. Once you've made your selection, complete the quick steps outlined in the shopping cart to secure your order of MLB tickets, and let Sourced Tickets handle the rest. We hope you have an enjoyable game day experience!"
],
NCAA_text:[
    "Sourced Tickets offers college football enthusiasts the opportunity to secure tickets to the most anticipated matchups of the season! From intense rivalries to prestigious bowl games, we're here to help you cheer on your alma mater or favorite team from the finest seats available. Browse through our extensive inventory of seats for any upcoming game on the college football schedule.Whether you're aiming to attend your team's season opener or the grand finale, we are committed to providing you with exceptional customer service. Our mission is to connect you with the perfect set of college football tickets."
]

};
